<template>
  <div>
    <div class="q-pa-md bg-black">
      <div
          class="full-width row justify-center content-center"
          style="height: 100vh"
      >
        <div class="watch">
          <div class="watch-header"> <q-badge rounded color="red" /><span style="margin-left: 10px">L4D2Love直播间</span></div>
          <video-player
              src="https://api.l4d2.app/live/livestream.m3u8"
              currentSrc="https://api.l4d2.app/live/livestream.m3u8"
              currentSource="https://api.l4d2.app/live/livestream.m3u8"
              crossorigin="anonymous"
              playsinline
              controls
              autoplay="true"
              muted="true"
              :volume="0.6"
              :width="800"
              :height="600"
              :playback-rates="[0.7, 1.0, 1.5, 2.0]"
              @mounted="handleMounted"
              @ready="handleEvent($event)"
              @play="handleEvent($event)"
              @pause="handleEvent($event)"
              @ended="handleEvent($event)"
              @loadeddata="handleEvent($event)"
              @waiting="handleEvent($event)"
              @playing="handleEvent($event)"
              @canplay="handleEvent($event)"
              @canplaythrough="handleEvent($event)"
              @timeupdate="handleEvent(player?.currentTime())"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import {VideoPlayer} from '@videojs-player/vue'
import 'video.js/dist/video-js.css'

export default defineComponent({
  name: "Watch",
  components: {
    VideoPlayer
  }
})
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Exo:400,700');

body {
  font-family: 'Exo', sans-serif;
}
.watch-header{
  height: 30px;
  background-color: white;
  line-height: 30px;
  font-size: 14px;
  border-radius: 10px 10px 0px 0px;
  padding-left: 10px;
}
</style>
